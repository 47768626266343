'use client';

import { useEffect, useRef, useState } from 'react';
import styles from './ScrollFade.module.scss';

function ScrollFade({
  children,
  tag,
  fadeinCallback,
}: {
  children?: React.ReactNode;
  tag: 'div' | 'li' | 'section';
  fadeinCallback?: () => void;
}) {
  const [visible, setVisible] = useState(false);
  const targetRef = useRef<HTMLDivElement & HTMLLIElement & HTMLElement>(null);
  const didVisibleRef = useRef(false); // 表示済みかどうかのフラグ

  useEffect(() => {
    const top = targetRef.current?.getBoundingClientRect().top;
    const handleScroll = () => {
      const scrollingTop = targetRef.current?.getBoundingClientRect().top;
      if (!scrollingTop) return;
      if (!didVisibleRef.current && scrollingTop < window.innerHeight + 60) {
        didVisibleRef.current = true;
        setVisible(true);

        // コールバック
        if (fadeinCallback) fadeinCallback();
      }
    };
    if (top && top > window.innerHeight) {
      window.addEventListener('scroll', handleScroll);
    } else {
      handleScroll();
    }
  }, [fadeinCallback, visible]);

  const Tag = tag;
  return (
    <Tag className={`${styles.componentStyle} ${visible ? styles.visible : ''}`} ref={targetRef}>
      {children}
    </Tag>
  );
}

export default ScrollFade;
