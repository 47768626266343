'use client';

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import Link from 'next/link';
import { useState } from 'react';
import { LotteryEvent } from '../../types';
import styles from './LotterySlider.module.scss';
import '@splidejs/react-splide/css';

function LotterySlider({
  lotteryEventList,
}: {
  lotteryEventList: Array<LotteryEvent>;
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={styles.componentStyle}>
      <Splide
        className={styles.splide}
        options={{
          autoplay: true,
          type: 'loop',
          arrows: false,
          pagination: false,
          pauseOnHover: false,
          height: '52.2vw',
        }}
        onMove={({ index }) => {
          setActiveIndex(index);
        }}
        aria-label='Splide Basic HTML Example'
        hasTrack={false}
      >
        <SplideTrack className={styles.splide}>
          {lotteryEventList.map((lotteryEvent) => (
            <SplideSlide key={lotteryEvent.id}>
              <Link href={`/lotteries/${lotteryEvent.id}`} className={styles.itemLink} prefetch={false}>
                <img src={lotteryEvent.imageUrl} alt={lotteryEvent.name} className={styles.itemImg} />
              </Link>
            </SplideSlide>
          ))}
        </SplideTrack>
        <div className={`${'splide__progress'} ${styles.progressBg}`}>
          <div className={`${'splide__progress__bar'} ${styles.progress}`} />
        </div>
      </Splide>
      <div className={styles.positions}>
        {lotteryEventList.map((lotteryEvent, index) => (
          <div
            key={`position_${lotteryEvent.id}`}
            className={`${styles.positionItem} ${activeIndex === index ? styles.active : ''}`}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default LotterySlider;
