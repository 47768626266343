'use client';

import { useEffect, useRef, useState } from 'react';
import styles from './Accordion.module.scss';

function Accordion({
  children,
  isOpened,
  startHeight = 0,
}: {
  children?: React.ReactNode;
  isOpened: boolean;
  startHeight?: number;
}) {
  const [height, setHeight] = useState(startHeight);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const scrollHeight = ref.current ? ref.current.scrollHeight : startHeight;
    setHeight(isOpened ? scrollHeight : startHeight);
  }, [isOpened, startHeight]);

  return (
    <div className={`${styles.componentStyle}`} style={{ height: `${height}px` }} ref={ref}>
      {children}
    </div>
  );
}

export default Accordion;
