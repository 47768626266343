'use client';

import keyWordsText from '@/assets/images/text/keywords.png';
import { Keyword } from '@/features/lottery/types';
import utilsStyles from '@/styles/modules/utils.module.scss';
import { Route } from 'next';
import { useRouter } from 'next/navigation';
import styles from './Keywords.module.scss';

function Keywords({
  keywords,
  activeKeywordId,
}: {
  keywords: Array<Keyword>;
  activeKeywordId?: number;
}) {
  const router = useRouter();
  const goLottery = (e: React.MouseEvent, keywordId: number) => {
    e.preventDefault();
    const params = new URLSearchParams(location.search);
    params.set('tag', String(keywordId));
    router.push(`/lotteries?${params.toString()}` as Route);
  };

  return (
    <div className={styles.componentStyle}>
      <p className={styles.titleText}>
        <img src={keyWordsText.src} alt='keywords' />
      </p>
      <ul className={styles.itemList}>
        {keywords.map((keyword) => (
          <li key={keyword.tagId}>
            <a
              className={`${styles.itemLink} ${utilsStyles.textBold} ${
                keyword.tagId === activeKeywordId ? styles.active : styles.inActive
              }`}
              onClick={(e) => {
                goLottery(e, keyword.tagId);
              }}
            >
              #{keyword.tagName}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Keywords;
