'use client';

import FaIcon, { faChevronDown, faChevronUp } from '@/components/icon/FaIcon/FaIcon';
import utilsStyles from '@/styles/modules/utils.module.scss';
import { useState } from 'react';
import Accordion from '../Accordion/Accordion';
import styles from './ResaleProhibited.module.scss';

function ResaleProhibited() {
  const [isOpened, setIsOpened] = useState(false);

  const toggleAccordion = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsOpened(!isOpened);
  };

  return (
    <div className={styles.componentStyle}>
      <button type='button' className={`${styles.head} ${utilsStyles.textBold}`} onClick={(e) => toggleAccordion(e)}>
        <span>※獲得した景品の転売・譲渡、デジタルコンテンツの公開は禁止しております</span>
        &nbsp;
        <span>{isOpened ? <FaIcon icon={faChevronUp}></FaIcon> : <FaIcon icon={faChevronDown}></FaIcon>}</span>
      </button>
      <Accordion isOpened={isOpened} startHeight={0}>
        <p className={`${utilsStyles.textCenter} ${styles.body}`}>
          ・獲得した景品をオークション等へ転売する行為、第三者への譲渡は禁止しております。
          <br />
          ・獲得した動画･画像･ボイス等のデジタルコンテンツは、出品者が著作権を有します。
          SNS等での公開、譲渡、その他著作権を侵害する行為は禁止しております。
          <br />
        </p>
      </Accordion>
    </div>
  );
}

export default ResaleProhibited;
