'use client';

import type { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export {
  faChevronRight,
  faClock,
  faSearch,
  faChevronUp,
  faChevronLeft,
  faChevronDown,
  faExclamationCircle,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';

export {
  faXTwitter,
  faInstagram,
  faYoutube,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

type Props = {
  icon: IconDefinition;
  size?: SizeProp;
};

function FaIcon({ icon, size }: Props) {
  return <FontAwesomeIcon icon={icon} size={size}></FontAwesomeIcon>;
}

export default FaIcon;
